<template>
<div>
  <nav-bar></nav-bar>
  <div class="container-fluid page-body-wrapper">
    <side-bar></side-bar>
    <!-- partial -->
    <div class="main-panel">
        <div class="content-wrapper">
            <div class="page-header">
                <h4 class="page-title">Client User Details</h4>
            </div>
            <div class="row">
              <div class="col-lg-12 grid-margin stretch-card">
                <div class="card">
                    <div v-if="is_API_call_running" class="text-center mt-5">
                      <b-spinner label=""></b-spinner>
                    </div>
                    <div class="card-body">
                  <h4 class="card-title">Admin Details</h4>
                  <form class="form-sample">
                    <div class="row">
                      <div class="col-md-4">
                        <div class="form-group row">
                          <div class="col-sm-12">
                            <label class="col-form-label">User Name</label>
                            <input type="text" class="form-control" v-model="$v.typeform.doctor_name.$model" :class="{ 'is-invalid':  $v.typeform.doctor_name.$error  }" name="doctor_name" placeholder="Doctor Name"/>
                            <div v-if="$v.typeform.doctor_name.$error" class="invalid-feedback">
                              <span v-if="!$v.typeform.doctor_name.required">Please enter user name</span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-4">
                        <div class="form-group row">
                          <div class="col-sm-12">
                            <label class="col-form-label">Email Address</label>
                            <input type="text" class="form-control" v-model="$v.typeform.doctor_email.$model" name="doctor_email_address" placeholder="Email Address" :class="{ 'is-invalid':  $v.typeform.doctor_email.$error  }" disabled/>

                          </div>
                        </div>
                      </div>
                      <div class="col-md-4">
                        <div class="form-group row">
                          <div class="col-sm-12">
                            <label class="col-form-label">Mobile Number</label>
                            <input type="text" class="form-control" v-model="$v.typeform.doctor_mobile_number.$model" name="doctor_mobile_number" placeholder="Doctor Mobile" :class="{ 'is-invalid':  $v.typeform.doctor_mobile_number.$error  }" disabled/>

                          </div>
                        </div>
                      </div>

                      <div class="col-md-4">
                        <div class="form-group row">
                          <div class="col-sm-12">
                            <label class="col-form-label">Emergency Mobile Number</label>
                            <input type="text" class="form-control"
                                v-model="$v.typeform.doctor_emergency_contact_number.$model"
                                name="doctor_emergency_mobile_number"
                                placeholder="Emergency Mobile Number"
                                :class="{ 'is-invalid':  $v.typeform.doctor_emergency_contact_number.$error  }"/>
                            <div v-if="$v.typeform.doctor_emergency_contact_number.$error" class="invalid-feedback">
                              <span v-if="!$v.typeform.doctor_emergency_contact_number.required">Please enter emergency mobile number</span>
                            </div>
                          </div>
                        </div>
                      </div>
                        <div class="col-md-4">
                          <label class="col-form-label">Department</label>
                          <div class="col-sm-12">
                            <div class="col-sm-10">
                              <multiselect v-model="department_selected" :options="departments"
                                            :multiple="true" :close-on-select="false" :clear-on-select="false"
                                            :preserve-search="true" placeholder="Department" label="department_name"
                                            track-by="department_name" :preselect-first="true" :showLabels="false"></multiselect>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-4">
                        <div class="form-group row">
                          <div class="col-sm-12">
                            <label class="col-form-label">ID Card Number</label>
                            <input type="text" class="form-control" v-model="$v.typeform.identification.$model" name="identification" placeholder="Identification" :class="{ 'is-invalid':  $v.typeform.identification.$error  }"/>
                            <div v-if="$v.typeform.identification.$error" class="invalid-feedback">
                              <span v-if="!$v.typeform.identification.required">Please enter id card number</span>
                            </div>
                          </div>
                        </div>
                     </div>
                      <div class="col-md-3">
                          <div class="form-group row">
                            <div class="col-sm-12">
                              <label class="col-form-label">Gender</label>
                              <multiselect v-model="$v.typeform.gender.$model" :options="gender_list"
                                           :multiple="false" :close-on-select="true" :clear-on-select="false"
                                           :preserve-search="true" placeholder="Gender"
                                           :preselect-first="true" :showLabels="false"></multiselect>
                              <div v-if="$v.typeform.gender.$error" class="invalid-feedback">
                                <span v-if="!$v.typeform.gender.required">Please select gender</span>
                              </div>
                            </div>
                          </div>
                      </div>
                      <div class="col-md-3">
                        <div class="form-group row">
                          <div class="col-sm-12">
                            <label class="col-form-label">Married?</label>
                            <multiselect v-model="$v.typeform.marital_status.$model" :options="option_yes_no"
                                       :multiple="false" :close-on-select="true" :clear-on-select="false"
                                       :preserve-search="true" placeholder="Marital Status"
                                       :preselect-first="true" :showLabels="false"></multiselect>
                            <div v-if="$v.typeform.marital_status.$error" class="invalid-feedback">
                              <span v-if="!$v.typeform.marital_status.required">Please select marital status</span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-3">
                        <div class="form-group row">
                          <div class="col-sm-12">
                            <label class="col-form-label">Birth Date</label>
                            <b-form-datepicker id="datepicker-birthdate" :max="today_date" v-model="$v.typeform.birth_date.$model"
                                    class="form-control" today-button='true'
                                    selected-variant='primary' reset-button='true'
                                    close-button='true' weekday-header-format='short'></b-form-datepicker>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-3">
                        <div class="form-group row">
                          <div class="col-sm-12">
                            <label class="col-form-label">Blood Group</label>
                            <multiselect v-model="$v.typeform.blood_group.$model" :options="blood_group_list"
                                           :multiple="false" :close-on-select="true" :clear-on-select="false"
                                           :preserve-search="true" placeholder="Blood Group"
                                           :preselect-first="true" :showLabels="false"></multiselect>
                            <div v-if="$v.typeform.blood_group.$error" class="invalid-feedback">
                              <span v-if="!$v.typeform.blood_group.required">Please select blood group</span>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="col-md-4">
                        <div class="form-group row">
                          <div class="col-sm-12">
                            <label class="col-form-label">Address</label>
                            <textarea rows="5" type="text"
                                        class="form-control"
                                        v-model="$v.typeform.doctor_address.$model"
                                        name="address" placeholder="Address"
                                        :class="{ 'is-invalid':  $v.typeform.doctor_address.$error }"/>
                            <div v-if="$v.typeform.doctor_address.$error" class="invalid-feedback">
                              <span v-if="!$v.typeform.doctor_address.required">Please enter address</span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-4">
                        <div class="form-group row">
                          <div class="col-sm-12">
                            <label class="col-form-label">City</label>
                            <input type="text" class="form-control"
                                    v-model="$v.typeform.doctor_city.$model"
                                    name="doctor_city" placeholder="City"
                                    :class="{ 'is-invalid':  $v.typeform.doctor_city.$error  }"/>
                            <div v-if="$v.typeform.doctor_city.$error" class="invalid-feedback">
                              <span v-if="!$v.typeform.doctor_city.required">Please enter city</span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-4">
                        <div class="form-group row">
                          <div class="col-sm-12">
                            <label class="col-form-label">Postal Code</label>
                            <input type="number" class="form-control"
                                    v-model="$v.typeform.doctor_postal_code.$model"
                                    name="doctor_postal_code" placeholder="Postal Code"
                                    :class="{ 'is-invalid':  $v.typeform.doctor_postal_code.$error  }"/>
                            <div v-if="$v.typeform.doctor_postal_code.$error" class="invalid-feedback">
                              <span v-if="!$v.typeform.doctor_postal_code.required">Please enter postal code</span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-3" >
                        <div class="form-group row">
                          <div class="col-sm-12">
                            <label class="col-form-label">Username</label>
                            <input type="text" class="form-control"
                                    v-model="$v.typeform.username.$model"
                                    name="username" placeholder="Username"
                                    :class="{ 'is-invalid':  $v.typeform.username.$error  }" disabled/>
                          </div>
                        </div>
                      </div>

                    </div>
                  </form>
                    <br/><br/>
                   <div class="col-md-12">
                       <b-button v-if="is_btn_spinner" variant="primary" disabled>
                           <b-spinner small type="grow"></b-spinner>Loading...
                       </b-button>
                       <button v-else type="button" @click="updateClientUserDetails()" class="btn btn-gradient-primary me-2">Update Details</button>
                   </div>
              </div>
              </div>
              </div>
            <Footer/>
            </div>
        </div>
    </div>
  </div>
</div>
</template>
<script>
import NavBar from "@/components/NavBarMaster.vue";
import Footer from "@/components/Footer.vue";
import SideBar from "@/components/SideBarMaster.vue";
import { mapActions } from "vuex";
import Multiselect from 'vue-multiselect';
import string from "../../../constant/strings.js"
import Swal from 'sweetalert2'
import { required, numeric } from "vuelidate/lib/validators";
import moment from "moment";

export default {
  name: 'Client User Details',
  title: string.PAGE_TITLE_CLIENT_USER_DETAILS,
  components: {
    NavBar,
    SideBar,
    Multiselect,
    Footer
  },
  data() {
    const now = new Date()
    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate())
    const maxDate = new Date(today)
    return {
      status :['Active','Inactive'],
      gender_list:['Male', 'Female'],
      blood_group_list:["A-", "B+", "B-", "AB+", "AB-", "O+", "O-"],
      option_yes_no :['Yes','No'],
      doctor_id:'',
      client_user_details: null,
      is_API_call_running: false,
      is_btn_spinner: false,
      errorMessage: 'No Data Found.',
      option_yes_no :['Yes','No'],
      typeform:{
        moment,
        doctor_name:'',
        doctor_mobile_number:'',
        identification:'',
        doctor_email:'',
        doctor_address:'',
        doctor_city:'',
        doctor_postal_code:'',
        doctor_emergency_contact_number:'',
        marital_status:'',
        username:'',
        gender:'',
        blood_group:'',
        selected_department_id:[],
        today_date: now.getFullYear() + '-' + (now.getMonth() + 1) + '-'  + now.getDate(),
        picker: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
        max: maxDate,
      },
      departments:[],
      department_selected:[],
    }
  },
    validations: {
    typeform: {
      doctor_name:{required},
      doctor_mobile_number:{required,numeric},
      identification:{},
      selected_department_id:{required},
      doctor_email:{required},
      doctor_address:{required},
      doctor_city:{required},
      doctor_postal_code:{required},
      doctor_emergency_contact_number:{required},
      marital_status:{required},
      gender:{required},
      birth_date:{required},
      blood_group:{required},
      username:{required},
    },
  },
  mounted() {
    this.getClientUserDetails();
    this.getDepartment()

  },
  methods: {
    ...mapActions("hospital", ["getClientUserListAPI", "getAppointmentDepartmentListAPI", "updateClientUserDetailsAPI"]),
    getClientUserDetails(){
      this.is_API_call_running = true;
      var bodyFormData = new FormData();;
      bodyFormData.append('user_id', this.$route.params.id);
      bodyFormData.append('client_id', this.$route.params.client_id);
      this.getClientUserListAPI(bodyFormData).then((response) => {
        this.is_API_call_running = false
        if (response.response_code == 200) {
            this.client_details = response.data;
            this.typeform= {
              doctor_name:response.data.name,
              doctor_mobile_number:response.data.mobile,
              identification:response.data.identification_number,
              department:'',
              doctor_email : response.data.email,
              doctor_address : response.data.address,
              doctor_city : response.data.doctor_city,
              doctor_postal_code : response.data.doctor_postal_code,
              doctor_emergency_contact_number : response.data.doctor_emergency_contact_number,
              marital_status : response.data.marital_status,
              username : response.data.username,
              gender : response.data.gender,
              birth_date : response.data.birth_date,
              blood_group : response.data.blood_group,
              status : response.data.status,
            }
            if(response.data.department.length > 0) {
                this.department_selected = response.data.department;
            }
        } else{
            this.errorMessage = response.message;
        }
      });
    },
    getDepartment(){
        var bodyFormData = new FormData();
        bodyFormData.append('client_id', this.$route.params.client_id);
          this.getAppointmentDepartmentListAPI(bodyFormData).then((response) => {
            if (response.response_code == 200) {
                this.departments = response.data;
            }
        });
    },

    updateClientUserDetails(){
      this.$v.$touch();

      this.typeform.selected_department_id = [];
      if(this.department_selected && this.department_selected.length > 0){
        this.department_selected.forEach((department_obj) => {
              this.typeform.selected_department_id.push(department_obj.id);
        })
      }
      this.typeform.selected_department_id = JSON.stringify(this.typeform.selected_department_id);

      if (!this.$v.typeform.$invalid) {
        this.is_btn_spinner=true;
        var bodyFormData = new FormData();
        bodyFormData.append('doctor_id', this.$route.params.id);
        bodyFormData.append('doctor_name', this.typeform.doctor_name);
        bodyFormData.append('doctor_mobile_number', this.typeform.doctor_mobile_number);
        bodyFormData.append('doctor_identification', this.typeform.identification);
        bodyFormData.append('doctor_email', this.typeform.doctor_email);
        bodyFormData.append('doctor_address', this.typeform.doctor_address);
        bodyFormData.append('doctor_city', this.typeform.doctor_city);
        bodyFormData.append('doctor_postal_code', this.typeform.doctor_postal_code);
        bodyFormData.append('doctor_emergency_contact_number', this.typeform.doctor_emergency_contact_number);
        bodyFormData.append('marital_status', this.typeform.marital_status);
        bodyFormData.append('gender', this.typeform.gender);
        bodyFormData.append('birth_date', this.typeform.birth_date);
        bodyFormData.append('blood_group', this.typeform.blood_group);
        bodyFormData.append('username', this.typeform.username);
        bodyFormData.append('department', this.typeform.selected_department_id);
        bodyFormData.append('client_id', this.$route.params.client_id);
        this.updateClientUserDetailsAPI(bodyFormData).then((response) => {
            this.is_btn_spinner=false;
          if (response.response_code == 200) {
            this.$toasted.success(response.message, {duration: 2000,});
          }else{
            this.$toasted.error(response.message, {duration: 2000,});
          }
        });
      }
    },
  }
}
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>